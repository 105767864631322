<template>
  <!-- -->
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header -->
      <div class="card-header px-6" style="background-color: #18ba70">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Infos promoteur</h3>
        </div>
        <div class="card-toolbar">
          <router-link :to="'/promoters/form/' + id" class="btn btn-color-white"
            >Modifier</router-link
          >
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="promoter" />
      <!-- Card body -->
      <div class="card-body pt-7 h-400px overflow-scroll">
        <div class="d-flex flex-column text-gray-700">
          <div class="mb-3">
            <div class="fw-bolder text-black">Nom du promoteur</div>
            <div v-if="promoter.name" class="pt-2">{{ promoter.name }}</div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Nom du dirigeant</div>
            <div v-if="promoter.director_name" class="pt-2">
              {{ promoter.director_name }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Adresse siège</div>
            <div v-if="promoter.head_office_address" class="pt-2">
              <span
                >{{ promoter.head_office_address.number }}
                {{ promoter.head_office_address.street }},
                {{ promoter.head_office_address.zipcode }},
                {{ promoter.head_office_address.city }}</span
              >
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Date d'ajout</div>
            <div v-if="promoter.created" class="pt-2">
              {{ getMomentDate(promoter.created) }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Date de mise à jour</div>
            <div v-if="promoter.updated" class="pt-2">
              {{ getMomentDate(promoter.updated) }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import moment from "moment";

export default {
  name: "PromoterDetails",
  components: {
    Loader,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["promoter"]),
  },
  methods: {
    ...mapActions(["getPermit"]),
    showPromoter(id) {
      this.getPromoter(id);
    },
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
  },
};
</script>
