<template>
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6 bg-primary">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Contacts</h3>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-color-light"
            data-bs-toggle="modal"
            data-bs-target="#addContactSelectModal"
          >
            + Ajouter
          </button>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="promoterContacts" />
      <!-- Card body -->
      <div
        v-if="promoterContacts"
        class="card-body pt-7 h-300px overflow-scroll"
      >
        <div
          v-if="promoterContacts.count > 0"
          class="d-flex flex-column text-gray-700"
        >
          <div v-for="contact in promoterContacts.results" :key="contact.id">
            <div class="mb-3">
              <router-link :to="'/contacts/' + contact.id" class="mb-0">
                {{ contact.last_name }} {{ contact.first_name }}
                <span
                  v-if="contact.relation_quality"
                  class="ms-2 badge bg-light-warning text-dark"
                >
                  {{ contact.relation_quality }}
                  <i class="bi bi-star-fill text-warning h6"></i>
                </span>
              </router-link>
              <div>
                <span v-if="contact.position">
                  {{ contact.position }}
                </span>
                <span v-else class="text">Fonction non fournie </span>
              </div>
              <div>
                <span v-if="contact.agency" class="mb-0">
                  <router-link
                    :to="'/agencies/' + contact.agency.id"
                    class="text-gray-700 mb-0"
                  >
                    {{ contact.agency.name }}
                  </router-link>
                </span>
                <span v-else class="text">Agence non fournie </span>
              </div>
              <div>
                <span v-if="contact.phone_number">
                  {{ contact.phone_number }}
                </span>
                <span v-else class="text">Numéro non fournie </span>
              </div>
              <div>
                <span v-if="contact.last_followup_author">
                  Dernière personne à l'avoir contacté:
                  {{ contact.last_followup_author }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-2 text-gray-700">
          Aucun contact associé à ce promoteur
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>

    <div
      id="addContactSelectModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="addContactSelectModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <!-- Modal header -->
          <div class="modal-header">
            <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter un contact</h3>
            <div
              class="btn btn-icon btn-sm btn-active-icon-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-2x">
                <CloseIcon />
              </span>
            </div>
          </div>
          <!-- Modal form -->
          <form @submit.prevent="linkContact">
            <!-- Modal body -->
            <div class="modal-body px-lg-12">
              <div v-if="contacts" class="mb-5 fv-row">
                <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span>Contacts</span>
                </label>
                <div class="d-flex">
                  <v-select
                    v-model="formLinkContact.contact_id"
                    class="form-control me-4"
                    :reduce="(contact) => contact.id"
                    :options="arrayContacts"
                    label="label"
                    placeholder="Choisir un contact"
                    @search="fetchOptionsContact"
                  />
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    +
                  </button>
                </div>
              </div>
              <div v-else>
                <Loader :data="contacts" />
              </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light me-3"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Add contact form modal -->
    <div
      id="exampleModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <!-- Modal header -->
          <div class="modal-header">
            <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter un contact</h3>
            <div
              class="btn btn-icon btn-sm btn-active-icon-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-2x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </div>
          <!-- Modal form -->
          <form @submit.prevent="createContact">
            <!-- Modal body -->
            <div class="modal-body px-lg-12">
              <div class="row mb-5">
                <div class="col-md-6 fv-row">
                  <label class="required fs-5 fw-bold mb-2">Nom</label>
                  <input
                    v-model="formContact.last_name"
                    type="text"
                    class="form-control form-control-solid"
                  />
                </div>
                <div class="col-md-6 fv-row">
                  <label class="required fs-5 fw-bold mb-2">Prénom</label>
                  <input
                    v-model="formContact.first_name"
                    type="text"
                    class="form-control form-control-solid"
                  />
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-md-6 fv-row">
                  <label class="required fs-5 fw-bold mb-2"
                    >Numéro de téléphone</label
                  >
                  <input
                    v-model="formContact.phone_number"
                    v-mask="'00 00 00 00 00'"
                    type="text"
                    class="form-control form-control-solid"
                  />
                </div>
                <div class="col-md-6 fv-row">
                  <label class="required fs-5 fw-bold mb-2"
                    >Second téléphone</label
                  >
                  <input
                    v-model="formContact.second_phone_number"
                    v-mask="'00 00 00 00 00'"
                    type="text"
                    class="form-control form-control-solid"
                  />
                </div>
              </div>
              <div class="d-flex flex-column mb-5 fv-row">
                <label class="fs-5 fw-bold mb-2">Email</label>
                <input
                  v-model="formContact.email"
                  class="form-control form-control-solid"
                />
              </div>
              <div class="d-flex flex-column mb-5 fv-row">
                <label class="fs-5 fw-bold mb-2">Poste</label>
                <select
                  v-model="formContact.position"
                  class="form-select form-select-solid form-select-lg me-4"
                >
                  <option value="Assistant de Direction">
                    Assistant de Direction
                  </option>
                  <option value="Assistant de programme">
                    Assistant de programme
                  </option>
                  <option value="Développeur">Développeur</option>
                  <option value="Directeur">Directeur</option>
                  <option value="Directeur Associé">Directeur Associé</option>
                  <option value="Directeur commercial">
                    Directeur commercial
                  </option>
                  <option value="Directeur d'Agence">Directeur d'Agence</option>
                  <option value="Directeur de développement">
                    Directeur de développement
                  </option>
                  <option value="Directeur de programme">
                    Directeur de programme
                  </option>
                  <option value="Directeur des Partenariats">
                    Directeur des Partenariats
                  </option>
                  <option value="Directeur des ventes bloc">
                    Directeur des ventes bloc
                  </option>
                  <option value="Directeur Général">Directeur Général</option>
                  <option value="Directeur Général Adjoint">
                    Directeur Général Adjoint
                  </option>
                  <option value="Gérant">Gérant</option>
                  <option value="Directeur Régional">Directeur Régional</option>
                  <option value=" Directeur technique">
                    Directeur technique
                  </option>
                  <option value="Directeur Opérationnel">
                    Directeur Opérationnel
                  </option>
                  <option value="Fondateur">Fondateur</option>
                  <option value="Juriste">Juriste</option>
                  <option value="Président">Président</option>
                  <option value="Responsable administration des ventes">
                    Responsable administration des ventes
                  </option>
                  <option value="Responsable">Responsable</option>
                  <option value="Responsable de développement">
                    Responsable de développement
                  </option>
                  <option value="Responsable de programme">
                    Responsable de programme
                  </option>
                </select>
              </div>
              <div class="d-flex flex-column mb-5 fv-row">
                <label class="fs-5 fw-bold mb-2">Agence</label>
                <div class="d-flex">
                  <AgenciesSelect @selected="selectAgencyOption" />
                  <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#addAgencyModal"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light me-3"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AgenciesSelect from "@/components/selects/AgenciesSelect";
import Loader from "@/components/Loader";

export default {
  name: "PromoterContacts",
  components: {
    AgenciesSelect,
    Loader,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      formCompleted: {
        id: null,
        contact: [],
      },
      arrayPromoters: [],
      arrayContacts: [],
      formContact: {
        id: null,
        first_name: "",
        last_name: "",
        phone_number: "",
        second_phone_number: "",
        email: "",
        position: "",
        promoter_id: "",
      },
      formLinkContact: {
        promoter_id: "",
        contact_id: null,
      },
      formPromoter: {
        id: null,
        name: "",
        director_name: "",
        head_office_address: "",
        DAU: "",
      },
      formAgency: {
        name: "",
        phone_number: "",
        promoter: "",
        address: "",
      },
    };
  },
  computed: {
    ...mapGetters(["promoterContacts"]),
    ...mapGetters(["contacts"]),
    ...mapGetters(["promoter"]),
  },
  methods: {
    ...mapActions(["getPromoterContacts"]),
    ...mapActions(["getContactsInPermit"]),
    ...mapActions(["postContact"]),
    ...mapActions(["searchContact"]),
    ...mapActions(["updatePromoterContact"]),
    async getAllPromoterContacts() {
      await this.getPromoterContacts(this.id);
    },
    async getAllContacts() {
      await this.getContactsInPermit();
      let array = [];
      this.contacts.results.forEach((element) => {
        array.push({
          label: element.first_name + " " + element.last_name,
          id: element.id,
        });
      });
      this.arrayContacts = array;
    },

    async linkContact() {
      this.formLinkContact.promoter = this.promoter;
      await this.updatePromoterContact(this.formLinkContact);
      await this.getAllPromoterContacts();
    },

    async createContact() {
      let contact_id = await this.postContact(this.formContact);
      if (contact_id) {
        this.formCompleted.contact.push(contact_id);
        this.formCompleted.id = this.id;
        let data = this.promoter.contacts;
        data.forEach((contactId) => {
          this.formCompleted.contact.push(contactId);
        });
      }
      await this.getAllPromoterContacts();
    },

    async fetchOptionsContact(search, loading) {
      if (search.length) {
        loading(true);
        let array = [];
        await this.searchContact(search);
        this.contacts.results.forEach((element) => {
          array.push({
            label: element.first_name + " " + element.last_name,
            id: element.id,
          });
        });
        this.arrayContacts = array;
        loading(false);
      }
    },

    selectAgencyOption(element) {
      this.formContact.agency_id = element;
    },
  },
  mounted() {
    this.getAllPromoterContacts();
    this.getAllContacts();
    this.formContact.promoter_id = this.id;
  },
};
</script>
