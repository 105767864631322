<template>
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6" style="background-color: #33d73e">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Agences</h3>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-color-white"
            data-bs-toggle="modal"
            data-bs-target="#addAgencyModal"
          >
            + Ajouter
          </button>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="promoterAgencies" />
      <!-- Card body -->
      <div
        v-if="promoterAgencies"
        class="card-body pt-7 h-300px overflow-scroll"
      >
        <div
          v-if="promoterAgencies.count > 0"
          class="d-flex flex-column text-gray-700"
        >
          <div v-for="agency in promoterAgencies.results" :key="agency.id">
            <div class="mb-3">
              <router-link :to="'/agencies/' + agency.id" class="mb-0">
                <span v-if="agency.name">
                  {{ agency.name }}
                </span>
              </router-link>
              <div>
                <span v-if="agency.address">
                  {{ agency.address.number + " " }}
                  {{ agency.address.street + " " }}
                  {{ agency.address.zipcode + " " }} {{ agency.address.city }}
                </span>
                <span v-else class="text">Adresse non fournie </span>
              </div>
              <div>
                <span v-if="agency.phone_number">
                  {{ agency.phone_number }}
                </span>
                <span v-else class="text">Adresse non fournie </span>
              </div>
              <div>
                <span
                  class="text-danger"
                  style="cursor: pointer"
                  @click.prevent="deleteSelectedAgency(agency.id)"
                  >Supprimer</span
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-2 text-gray-700">
          Aucune agence associée à ce promoteur
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
    <!-- Add agency modal -->
    <div
      id="addAgencyModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="addAgencyModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <!-- Modal header -->
          <div class="modal-header">
            <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter une agence</h3>
            <div
              class="btn btn-icon btn-sm btn-active-icon-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-2x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </div>
          <form class="form">
            <!-- Modal body -->
            <div class="modal-body px-lg-12">
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label required fw-bold fs-6"
                  >Nom de l'agence</label
                >
                <div class="col-lg-10 fv-row">
                  <input
                    v-model="formAgency.name"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Nom de l'agence"
                  />
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label required fw-bold fs-6"
                  >Téléphone de l'agence</label
                >
                <div class="col-lg-10 fv-row">
                  <input
                    v-model="formAgency.phone_number"
                    v-mask="'00 00 00 00 00'"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Téléphone l'agence"
                  />
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label required fw-bold fs-6"
                  >Adresse de l'agence</label
                >
                <div class="col-lg-10">
                  <AddressInput
                    :can-create-address="canCreateAgencyAddress"
                    @addressCreated="catchAgencyAddressId"
                  ></AddressInput>
                </div>
              </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-toggle="modal"
                data-bs-target="#addAgencyModal"
              >
                Fermer
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#addAgencyModal"
                @click.prevent="createAddressThenAgency()"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import AddressInput from "@/components/selects/AddressInput";

export default {
  name: "PromoterAgencies",
  components: {
    Loader,
    AddressInput,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
      formAgency: {
        name: "",
        phone_number: "",
        promoter: "",
        address: "",
      },
      canCreateAgencyAddress: false,
    };
  },
  computed: {
    ...mapGetters(["promoterAgencies"]),
  },
  mounted() {
    this.getAllPromoterAgencies();
  },
  methods: {
    ...mapActions(["getPromoterAgencies"]),
    ...mapActions(["postAgency"]),
    ...mapActions(["deleteAgency"]),

    // Create promoter agencies
    async createAddressThenAgency() {
      await this.createAgencyAdress();
      this.canCreateAgencyAddress = false;
    },
    createAgencyAdress() {
      this.canCreateAgencyAddress = true;
    },
    async catchAgencyAddressId(value) {
      this.formAgency.address = value;
      this.canCreateAgencyAddress = false;
      this.formAgency.promoter = this.id;
      await this.postAgency(this.formAgency);
      this.getAllPromoterAgencies();
      this.formAgency = {};
    },

    // Get and delete promoter agencies
    async getAllPromoterAgencies() {
      await this.getPromoterAgencies(this.id);
    },
    async deleteSelectedAgency(id) {
      await this.deleteAgency(id);
      this.getAllPromoterAgencies();
    },
  },
};
</script>
