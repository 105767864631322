<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid mb-12">
    <!-- Header -->
    <div v-if="promoter" class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">
            {{ promoter.name }}
          </h1>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <Loader :data="promoter" />
    <!-- Body -->
    <div v-if="promoter" class="post fs-6 d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
          <PromoterDetails :id="promoter.id" />
          <PromoterContacts :id="promoter.id" />
          <PromoterAgencies :id="promoter.id" />
        </div>
        <PromoterPermits :id="promoter.id" class="mt-9" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useRoute } from "vue-router";
import Loader from "@/components/Loader";
import PromoterDetails from "@/components/promoters/PromoterDetails";
import PromoterContacts from "@/components/promoters/PromoterContacts";
import PromoterAgencies from "@/components/promoters/PromoterAgencies";
import PromoterPermits from "@/components/promoters/PromoterPermits";

export default {
  name: "PromotersInfos",
  components: {
    Loader,
    PromoterDetails,
    PromoterContacts,
    PromoterAgencies,
    PromoterPermits,
  },
  data() {
    return {
      id: null,
    };
  },
  computed: {
    ...mapGetters(["promoter"]),
  },
  methods: {
    ...mapActions(["getPromoter"]),
    showPromoter(id) {
      this.getPromoter(id);
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    const route = useRoute();
    this.id = route.params.id;
    this.showPromoter(route.params.id);
  },
};
</script>
