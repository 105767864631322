<template>
  <div class="col-md-12">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6" style="background-color: #20c997">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Permis</h3>
        </div>
        <div class="d-flex justify-content-end">
          <div class="me-3 d-flex align-items-center">
            <input
              v-model="scrollValue"
              type="range"
              min="0"
              :max="maxScroll"
              class="form-range"
              @input="scrollTable"
            />
          </div>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="promoterPermits" />
      <!-- Card body -->
      <div v-if="promoterPermits">
        <div v-if="promoterPermits.count > 0">
          <div class="card-body p-0">
            <div
              ref="tableResponsivePromoterPermits"
              class="table-responsive"
              @scroll="onTableScroll"
            >
              <table
                v-if="promoterPermits.results"
                class="table table-flush align-middle table-row-bordered table-row-solid gy-4"
              >
                <thead>
                  <tr class="text-start fw-bolder fs-6 text-uppercase gs-0">
                    <th class="min-w-125px ps-10">
                      Num_DAU
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingNumDau()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingNumDau()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      Promoteur
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingPromoter()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingPromoter()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      Contact principal
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingPrincipalContact()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingPrincipalContact()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      Numéro du contact principal
                    </th>
                    <th class="min-w-125px ps-9">
                      Localité demandeur
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingLocaliteDem()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingLocaliteDem()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">Adresse</th>
                    <th class="min-w-125px ps-9">
                      ADR LOCALITE TER
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingAdrLocaliteTer()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingAdrLocaliteTer()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      ADR CODPOST TER
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingAdrCodpostTer()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingAdrCodpostTer()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      DEP
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingDep()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingDep()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      NB LGT TOT CREES
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingNbLgtTotCrees()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingNbLgtTotCrees()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      Nb LLS
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingNblls()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingNblls()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">Décote prix ancien</th>
                    <th class="min-w-125px ps-9">
                      Situation de l'opération
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingSpecificCase()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingSpecificCase()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      Étape de la discussion
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingDiscussionStage()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingDiscussionStage()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      Date réelle autorisation
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingDateReelleAutorisation()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingDateReelleAutorisation()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      Date réelle doc
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingDateReelleDoc()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingDateReelleDoc()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">
                      Date réelle daact
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="ascendingDateReelleDaact()"
                      >
                        <AscendingIcon />
                      </span>
                      <span
                        class="svg-icon svg-icon-1"
                        @click.prevent="descendingDateReelleDaact()"
                      >
                        <DescendingIcon />
                      </span>
                    </th>
                    <th class="min-w-125px ps-9">Propriétaire du permis</th>
                  </tr>
                </thead>
                <tbody class="fw-bold text-gray-700">
                  <tr
                    v-for="permit in promoterPermits.results"
                    :key="permit.id"
                  >
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.num_dau"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.num_dau }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.principal_contact"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        <span v-if="permit.principal_contact.promoter_name">
                          {{ permit.principal_contact.promoter_name }}</span
                        ></router-link
                      >
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.principal_contact"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        <span
                          v-if="permit.principal_contact.first_name"
                          class="me-1"
                        >
                          {{ permit.principal_contact.first_name }}</span
                        >
                        <span v-if="permit.principal_contact.last_name">{{
                          permit.principal_contact.last_name
                        }}</span>
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.principal_contact"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        <span v-if="permit.principal_contact.phone_number">
                          {{ permit.principal_contact.phone_number }}</span
                        ></router-link
                      >
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.localite_dem"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.localite_dem }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="
                          permit.adr_num_ter ||
                          permit.adr_typevoie_ter ||
                          permit.adr_libvoie_ter
                        "
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        <span v-if="permit.adr_num_ter" class="mr-1">{{
                          permit.adr_num_ter + " "
                        }}</span>
                        <span v-if="permit.adr_typevoie_ter" class="mr-1">{{
                          permit.adr_typevoie_ter + " "
                        }}</span>
                        <span v-if="permit.adr_libvoie_ter" class="mr-1">{{
                          permit.adr_libvoie_ter
                        }}</span>
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie</router-link
                      >
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.adr_localite_ter"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.adr_localite_ter }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.adr_codpost_ter"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.adr_codpost_ter }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.dep"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.dep }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.nb_lgt_tot_crees"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.nb_lgt_tot_crees }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.nb_lls"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.nb_lls }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.city.decote_prix_ancien"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ Math.round(permit.city.decote_prix_ancien * 100) }}
                        %
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.specific_case"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.specific_case }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.discussion_stage"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.discussion_stage }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.date_reelle_autorisation"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.date_reelle_autorisation }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.date_reelle_doc"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.date_reelle_doc }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.date_reelle_daact"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.date_reelle_daact }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                    <td class="w-250px min-w-175px ps-10">
                      <router-link
                        v-if="permit.owner"
                        :to="'/permits/' + permit.id"
                        class="text-black"
                      >
                        {{ permit.owner.first_name + " " }}
                        {{ permit.owner.last_name }}
                      </router-link>
                      <router-link
                        v-else
                        :to="'/permits/' + permit.id"
                        class="text-black-50"
                      >
                        Information non fournie
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <div
              v-if="promoterPermits"
              class="row justify-content-center justify-content-sm-between align-items-sm-center"
            >
              <TableFooter
                v-if="promoterPermits"
                :all-data="promoterPermits"
                :default-size="30"
                :has-query="false"
                @selectedSize="getSizeSelected"
                @nextPage="showNextPermits(promoterPermits.next)"
                @previousPage="showPreviousPermits(promoterPermits.previous)"
                @change="getAllPromoterPermits"
              />
            </div>
          </div>
        </div>
        <div v-else class="card-body ms-1">
          <div class="alert alert-secondary">
            <div class="d-flex flex-column">
              <span class="mb-1 text-dark"
                >Aucun permit associé à ce promoteur</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import Loader from "@/components/Loader";
import AscendingIcon from "@/components/icons/AscendingIcon";
import DescendingIcon from "@/components/icons/DescendingIcon";
import TableFooter from "@/components/TableFooter";

export default {
  name: "PromoterPermits",
  components: {
    Loader,
    AscendingIcon,
    DescendingIcon,
    TableFooter,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      filter: {
        id: "",
        order: "",
        size: "",
        defaultSize: "",
      },
      scrollValue: 0,
      maxScroll: 100,
    };
  },
  computed: {
    ...mapGetters(["promoterPermits"]),
  },
  methods: {
    ...mapActions(["getPromoterPermits"]),
    ...mapActions(["nextPaginate"]),
    ...mapActions(["previousPaginate"]),
    async getAllPromoterPermits() {
      this.filter.id = this.id;
      await this.getPromoterPermits(this.filter);
    },
    async getSizeSelected(res) {
      this.filter.size = res.sizeSelected;
    },
    // Ordering
    ascendingNumDau() {
      this.filter.order = "num_dau";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingNumDau() {
      this.filter.order = "-num_dau";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingPromoter() {
      this.filter.order = "promoter";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingPromoter() {
      this.filter.order = "-promoter";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingPrincipalContact() {
      this.filter.order = "principal_contact";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingPrincipalContact() {
      this.filter.order = "-principal_contact";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingLocaliteDem() {
      this.filter.order = "localite_dem";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingLocaliteDem() {
      this.filter.order = "-localite_dem";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingAdrLocaliteTer() {
      this.filter.order = "adr_localite_ter";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingAdrLocaliteTer() {
      this.filter.order = "-adr_localite_ter";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingAdrCodpostTer() {
      this.filter.order = "adr_codpost_ter";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingAdrCodpostTer() {
      this.filter.order = "-adr_codpost_ter";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingDep() {
      this.filter.order = "dep";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingDep() {
      this.filter.order = "-dep";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingNbLgtTotCrees() {
      this.filter.order = "nb_lgt_tot_crees";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingNbLgtTotCrees() {
      this.filter.order = "-nb_lgt_tot_crees";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingNblls() {
      this.filter.order = "nb_lls";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingNblls() {
      this.filter.order = "-nb_lls";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingSpecificCase() {
      this.filter.order = "specific_case";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingSpecificCase() {
      this.filter.order = "-specific_case";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingDiscussionStage() {
      this.filter.order = "discussion_stage";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingDiscussionStage() {
      this.filter.order = "-discussion_stage";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingDateReelleAutorisation() {
      this.filter.order = "date_reelle_autorisation";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingDateReelleAutorisation() {
      this.filter.order = "-date_reelle_autorisation";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingDateReelleDoc() {
      this.filter.order = "date_reelle_doc";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingDateReelleDoc() {
      this.filter.order = "-date_reelle_doc";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    ascendingDateReelleDaact() {
      this.filter.order = "date_reelle_daact";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    descendingDateReelleDaact() {
      this.filter.order = "-date_reelle_daact";
      store.commit("promoterPermits", this.promoterPermits);
      this.getAllPromoterPermits();
    },
    // Paginations
    async showNextPermits(url) {
      this.permitsData = await this.nextPaginate(url);
      store.commit("promoterPermits", {
        ...this.permitsData,
      });
    },
    async showPreviousPermits(url) {
      this.permitsData = await this.previousPaginate(url);
      store.commit("promoterPermits", {
        ...this.permitsData,
      });
    },
    scrollTable() {
      const tableContainer = this.$refs.tableResponsivePromoterPermits;
      if (tableContainer) {
        const scrollPercentage = this.scrollValue / this.maxScroll;
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        const scrollAmount = maxScrollAmount * scrollPercentage;
        tableContainer.scrollLeft = scrollAmount;
      }
    },
    onTableScroll() {
      const tableContainer = this.$refs.tableResponsivePromoterPermits;
      if (tableContainer) {
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        if (maxScrollAmount > 0) {
          const scrollPercentage = tableContainer.scrollLeft / maxScrollAmount;
          this.scrollValue = scrollPercentage * this.maxScroll;
        }
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    this.getAllPromoterPermits();
  },
};
</script>
